import React, { Component } from 'react';
import {
    Button, ButtonGroup, Nav, ToggleButton, Navbar, NavDropdown, Form, FormControl, Carousel,
    Container,
    Card,
    Row,
    Col,
    Modal,
    InputGroup,

} from 'react-bootstrap';

import { post, initLogindata, get } from '../../../utils/common';
import { put, build } from '../../../utils/mixstate';
import FormItem from '../../../component/FormItem';

var mixer = build(['get_appsecret_time']);

export default class View extends Component {

    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            validated: false,
            datas: {}
        };
        mixer.mix(this);
        FormItem.mix(this);
    }
    componentDidMount() {
        
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.get_appsecret_time !== prevState.get_appsecret_time && this.state.get_appsecret_time) {
            this.setState({
                visible: true,
                validated: false,
                datas: {}
            });
            this.$setFormData('datas', this.props.row);
        }
    }

    onHide = () => {
        this.setState({
            visible: false
        });
    }

    save = () => {
      const {id} = this.props && this.props.row;
      const {email} = this.state.datas;
      post('/usersApplication/sendEmail', {id, email}, json => {
          if (json.errorMsg) {
              toast({
                  title: '错误',
                  message: json.errorMsg
              })
              return;
          } else if (json.feedbackMsg) {
              toast({
                  type: 'info',
                  message: json.feedbackMsg
              });
          }
          this.onHide();
      }, err => {
          toast({
              title: '错误',
              message: err
          });
      })

    }

    render() {
        return <Modal show={this.state.visible} onHide={this.onHide} centered backdrop="static" >
            <span className="dialog-close-button iconfont icon-guanbi" onClick={this.onHide} ></span>
            <Modal.Body className="mx-5 my-3">
                <div className="mb-3">
                    <h4 className="text-center mb-5 font-weight-bold">
                        <span className="border-primary text-bold px-3 pb-2" style={{ borderBottom: '4px solid blue' }}>获取密钥</span>
                    </h4>
                </div>
                <div className="mb-3"> 密钥将发送到下方的邮箱: </div>
                <Form ref={this.refForm} noValidate validated={false}>
                    <Form.Row>
                        <Form.Group as={Col} >
                            <FormItem statename="datas" context={this} type="email" placeholder="请输入邮箱" 
                                required name="email"
                            />
                        </Form.Group>
                    </Form.Row>
                    <Button onClick={this.save} disabled={!this.state.datas_valid} className="mt-3" variant="primary" type="button" block> 确定 </Button>
                </Form>
            </Modal.Body>
        </Modal>
    }
}