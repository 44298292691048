import React, { Component } from 'react';
import {
    Button, ButtonGroup, Nav, ToggleButton, Navbar, NavDropdown, Form, FormControl, Carousel,
    Container,
    Card,
    Row,
    Col,
} from 'react-bootstrap';

import tableRender from '../../../component/tableRender';
import { post } from '../../../utils/common';
import { format1 } from '../../../utils/time';
import DockingViewDialog from './DockingViewDialog';
import { put } from '../../../utils/mixstate';
import confirm from '../../../component/confirm';

// const states = [{
//   id: -1,
//   name: '停用'
// },{
//   id: 0,
//   name: '待审核'
// },{
//   id: 1,
//   name: '已审核'
// },{
//   id: 99,
//   name: '不通过'
// }];

// const stateMap = {};

// states.forEach( item => stateMap[item.id] = item.name)

class View extends Component {

  constructor(props){
    super(props);
  }

  state = {
    datas: []
  } 

  componentDidMount(){
    this.load();
  }

  load = () => {
    post('usersApplication/listApplyHospital', null, json => {
      this.setState({
        datas: (json && json.data) || []
      })
    });
  }

  delApply = (row) => {
    confirm({
      title: '删除',
      message: '确认删除应用吗',
      handler: r => {
        if (r === 'yes'){
          post('usersApplication/delApplyHospital', { id: row.id }, json => {
            if (json.errorMsg) {
              toast({
                title: '错误',
                message: json.errorMsg
              })
              return;
            } else if (json.feedbackMsg) {
              toast({
                type: 'info',
                message: json.feedbackMsg
              });
            }
            this.load();
              
          })
        }
      }
    });

  }


  applyApp = () => {
    put('time_apply_app', Date.now());
  }

  columns = [{
    title: '医疗机构名称',
    field: 'name'
  }, {
    title: '平台医疗机构代码',
    field: 'hospitalCode',
    width: 10,
    render: v => {
      return <>
        <span style={{fontWeight: 700}}>{v}</span>
      </>
    }
  }, {
    title: '药企机构代码',
    field: 'medCenterId',
    render: v => {
      return <>
        <span style={{fontWeight: 700}}>{v}</span>
      </>
    }
  }, {
    title: '对接药企',
    field: 'medCenterName'
  }, {
    title: '申请时间',
    render: v => v ? format1(v) : null,
    field: 'createTime'
  }, {
    title: '状态',
    field: 'checkDesc'
  }, {
    title: '操作',
    render: (v, row) => {
        return <>
        {row.checkDesc === '已审核' ? '' : 
          <Button variant="link" size="sm" onClick={ () => this.delApply(row)} style={{color: 'red'}}>删除</Button>
        }
            
        </>
    },
    field: 'operate'
  }]

  render(){

    return (
      <>
        <div className="py-3">
            <Button onClick={ this.applyApp } >立即申请</Button>
        </div>
        <div className="pb-5">
            {
                tableRender(this.columns, this.state.datas, { empty: <div className="text-center p-3">没有数据</div>})
            }
        </div>
        <DockingViewDialog onApplyed={this.load} />
      </>
    );
  }
}

export default View;