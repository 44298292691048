import React, { Component } from 'react';
import {
    Button,
    Modal,
} from 'react-bootstrap';

import ReactDOM from 'react-dom';



class Confirm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            visible: true
        };
    }

    close = () => {
        this.setState({
            visible: false
        });
    }

    onHide = () => {
        this.close();
        this.props.handler && this.props.handler()
    }

    yesClick = () => {
        this.close();
        this.props.handler && this.props.handler('yes')
    }

    noClick = () => {
        this.close();
        this.props.handler && this.props.handler('no')
    }

    onExited = () => {
        if (this.props.dom) {
            var div = this.props.dom;
            ReactDOM.unmountComponentAtNode(div);
            div.parentElement.removeChild(div);
        }
    }

    render() {
        return <Modal show={this.state.visible} centered backdrop="static" onHide={this.onHide} onExited={this.onExited} >
            <Modal.Header closeButton>
                <Modal.Title >
                    {this.props.title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="p-3">
                    {this.props.message}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div style={{ textAlign: 'center' }}>
                    <Button onClick={this.noClick} className="mt-3" variant="light" type="button" style={{ padding: '0.4em 2.5em', marginRight: '1em' }} > {this.props.noText}  </Button>
                    <Button onClick={this.yesClick} className="mt-3" variant="primary" type="button" style={{ padding: '0.4em 2.5em', marginLeft: '1em' }} > {this.props.yesText} </Button>
                </div>
            </Modal.Footer>
        </Modal>
    }

}

const confirm = ({ title = '确认', message, yesText = '确定', noText = '取消', handler } = {}) => {
    var div = document.createElement('div');
    document.body.appendChild(div);
    ReactDOM.render(<Confirm dom={div} { ...{
        title, message, yesText, noText, handler
    }} />, div);
}

export default confirm;

