import React, { Component } from 'react';
import {
    Button, ButtonGroup, Nav, ToggleButton, Navbar, NavDropdown, Form, FormControl, Carousel,
    Container,
    Card,
    Row,
    Col,
} from 'react-bootstrap';

import { put, build } from '../../../utils/mixstate';
import { config, post } from '../../../utils/common';
import FormItem from '../../../component/FormItem';
import types from '../../../datas/userTypes';
import ChangePasswordDialog from './ChangePasswordDialog';
import ChangePhoneDialog from './ChangePhoneDialog';
import ChangeEmailDialog from './ChangeEmailDialog';

var mixer = build(['logindata']);

class View extends Component {

    constructor(props) {
        super(props);
        mixer.mix(this);
        FormItem.mix(this);

    }

    componentDidMount() {
        post('users/getUsersInfo', null, json => {
            console.log(json);
            this.setState({
                datas: json.datas
            }, this.$);
            this.$setFormData('datas', json.data);

        });
    }

    save = () => {
        post('users/modifyUsersInfo', this.state.datas, (json = {}) => {
            console.log(json);
            if (json.errorMsg){
                toast({
                    title: '错误',
                    message: json.errorMsg
                })
                return;
            } else if (json.feedbackMsg){
                toast({
                    type: 'info',
                    message: json.feedbackMsg
                });
            }
        }, json => {
            console.log('fail', json);

        });
    }

    changepassword = () => {
        put('changepassword_time', Date.now());
    }

    changephone = () => {
        put('change_phone_time', Date.now());
    }

    changeEmail = () => {
        put('change_email_time', Date.now());
    }

    render() {
        return (
            <div className="px-3 pb-5 pt-2">
                <div style={{ margin: '3em 6em', width: '50%' }}>
                    <Form.Row>
                        <Form.Label column sm="3" className="text-right">
                            <span className="red">*</span>
                            企业名称：
                        </Form.Label>
                        <Form.Group as={Col} sm="8">
                            <FormItem
                                statename="datas"
                                context={this} type="text"
                                placeholder="企业名称"
                                required minLength="2"
                                name="companyName"
                            />
                            <Form.Control.Feedback type="invalid">
                                请输入企业名称 (至少为2个字符)
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Label column sm="3" className="text-right">
                            <span className="red">*</span>
                            联系人：
                        </Form.Label>
                        <Form.Group as={Col} sm="8">
                            <FormItem
                                statename="datas"
                                context={this} type="text"
                                required minLength="2"
                                name="contact"
                            />
                            <Form.Control.Feedback type="invalid">
                                请输入联系人 (至少为2个字符)
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Label column sm="3" className="text-right">
                            <span className="red">*</span>
                            人员类型：
                        </Form.Label>
                        <Form.Group as={Col} sm="8">
                            <FormItem statename="datas" context={this}
                                as="select"
                                required
                                name="roleId"
                            >
                                {
                                    types.map(item => {
                                        return <option value={item.id}>{item.name}</option>
                                    })
                                }
                            </FormItem>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Label column sm="3" className="text-right">
                            邮箱：
                        </Form.Label>
                        <Col sm="8" className="d-flex flex-row align-items-center justify-content-end">
                            <div className="flex1">
                                {this.state.datas && this.state.datas.email }
                            </div>
                            {/* <Button variant="link" onClick={this.changeEmail} >更换邮箱</Button> */}
                        </Col>
                    </Form.Row>
                    <Form.Row>
                        <Form.Label column sm="3" className="text-right">
                            手机号：
                        </Form.Label>
                        <Col sm="8" className="d-flex flex-row align-items-center ">
                            <div className="flex1">
                                {this.state.datas && this.state.datas.userTel }
                            </div>
                            <Button variant="link" onClick={this.changephone} >更换手机号</Button>
                        </Col>
                    </Form.Row>
                    <Form.Row>
                        <Form.Label column sm="3" className="text-right">
                            登录密码：
                        </Form.Label>
                        <Col sm="8" className="d-flex flex-row align-items-center justify-content-end">
                            <Button variant="link" onClick={this.changepassword} >修改</Button>
                        </Col>
                    </Form.Row>
                    <Form.Row>
                        <Col sm={{span: 5, offset: 6}} >
                            <Button onClick={this.save } disabled={!this.state.datas_valid} className="mt-3" variant="primary" type="button" block> 保存 </Button>
                        </Col>
                    </Form.Row>
                </div>
                <ChangePasswordDialog />
                <ChangePhoneDialog />
                <ChangeEmailDialog />
            </div>
        );
    }
}

export default View;
