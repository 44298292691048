import React, { Component } from 'react';
import {
    Nav, Toast, Alert, Collapse, Fade
} from 'react-bootstrap';
import ReactDOM from 'react-dom'

class AlertWrap extends Component {

    constructor(props) {
        super(props);
    }

    state = { show: true }

    refAlert = el => {
        if (el){
            this.setState({
                height: ReactDOM.findDOMNode(el).getBoundingClientRect().height
            })
        }
    }
    
    render() {
        return <Alert ref={ this.refAlert } {...this.props} style={{height: 'max-content', minWidth: '350px', maxWidth: '50%', margin: 15, marginTop: this.state.show || !this.state.height ? 0 : -15 - this.state.height + 'px' , transition: 'all .3s linear' }} show={this.state.show}>{this.props.children}</Alert>
            // </div>
    }

}

export default class Notification extends Component {

    toasts = []

    state = {
        toasts: this.toasts
    }

    show = ({ title, message, delay = 5000, type = 'warning' }) => {

        const onClose = () => {
            toast.instance && toast.instance.setState({
                show: false
            });
            setTimeout(() => {
                this.setState({
                    toasts: this.toasts = this.toasts.filter(item => item !== toast)
                });
            }, 2000);
        };
        setTimeout(onClose, delay);

        const toast = {
            view: <AlertWrap className="shadow" variant={type} ref={ref => toast.instance = ref} onClose={onClose} delay={delay} dismissible >
                {
                    !title ? null :
                        <Alert.Heading>
                            {/* <img
                    src="holder.js/20x20?text=%20"
                    className="rounded mr-2"
                    alt=""
                /> */}
                            <strong className="mr-auto">{title}</strong>
                        </Alert.Heading>
                }
                <div>{message}</div>
            </AlertWrap>
        };

        this.setState({
            toasts: this.toasts = [...this.toasts, toast]
        });

    }

    render() {
        return <div className="toast-wrap">
            {this.state.toasts.map(item => item.view)}
        </div>
    }

}