import React from 'react';
import {
    Button, ButtonGroup, Nav, ToggleButton, Navbar, NavDropdown, Form, FormControl, Carousel,
    Container,
    Card,
    Row,
    Col,
} from 'react-bootstrap';

import NavLink from '../../../component/NavLink';

import { config } from '../../../utils/common';


function View(props) {
    return (
        <div className="px-3 pb-5 pt-2">
            <h3 >
                快速接入    
                <span className="tip-update text-secondary" >更新时间：{config.update}</span>
            </h3>
            <p className="p">
                使用唐古远志中药处方流转平台需要按照接入流程完成快速接。
            </p>

            <div className="process p-4 bg-white text-center">

                <div className="d-flex flex-row">
                    <div className="flex-grow-1 d-flex flex-column flex1">
                        <div className="d-flex flex-row">
                            <hr className="flex-grow-1" style={{ borderColor: 'transparent' }} />
                            <div className="text-center align-self-center rounded-circle bg-primary" style={{ width: '1.8em', height: '1.8em', lineHeight: '1.8em', color: 'white' }} >1</div>
                            <hr className="flex-grow-1" />
                        </div>
                        <h5 className="text-center my-3">
                            注册/登录
                                </h5>
                        <p className="info text-secondary px-3" >
                            注册填写个人信息，成为平台用户
                                </p>
                    </div>

                    <div className="flex-grow-1 d-flex flex-column flex1">
                        <div className="d-flex flex-row">
                            <hr className="flex-grow-1" />
                            <div className="text-center align-self-center rounded-circle bg-primary" style={{ width: '1.8em', height: '1.8em', lineHeight: '1.8em', color: 'white' }} >2</div>
                            <hr className="flex-grow-1" />
                        </div>
                        <h5 className="text-center my-3">
                            等待通过
                                </h5>
                        <p className="info text-secondary px-3" >
                            注册后，待平台审核资料
                                </p>
                    </div>

                    <div className="flex-grow-1 d-flex flex-column flex1">
                        <div className="d-flex flex-row">
                            <hr className="flex-grow-1" />
                            <div className="text-center align-self-center rounded-circle bg-primary" style={{ width: '1.8em', height: '1.8em', lineHeight: '1.8em', color: 'white' }} >3</div>
                            <hr className="flex-grow-1" />
                        </div>
                        <h5 className="text-center my-3">
                            创建应用
                                </h5>
                        <p className="info text-secondary px-3" >
                            创建我的应用，待平台审核申请
                                </p>
                    </div>

                    <div className="flex-grow-1 d-flex flex-column flex1">
                        <div className="d-flex flex-row">
                            <hr className="flex-grow-1" />
                            <div className="text-center align-self-center rounded-circle bg-primary" style={{ width: '1.8em', height: '1.8em', lineHeight: '1.8em', color: 'white' }} >4</div>
                            <hr className="flex-grow-1" />
                        </div>
                        <h5 className="text-center my-3">
                            接口配置
                                </h5>
                        <p className="info text-secondary px-3" >
                            自主调试API接口进行测试
                                </p>
                    </div>
                    <div className="flex-grow-1 d-flex flex-column flex1">
                        <div className="d-flex flex-row">
                            <hr className="flex-grow-1" />
                            <div className="text-center align-self-center rounded-circle bg-primary" style={{ width: '1.8em', height: '1.8em', lineHeight: '1.8em', color: 'white' }} >5</div>
                            <hr className="flex-grow-1" style={{ borderColor: 'transparent' }} />
                        </div>
                        <h5 className="text-center my-3">
                            完成
                                </h5>
                        <p className="info text-secondary px-3" >
                            测试通过后即可
                                </p>
                    </div>
                </div>
            </div>

            <div className="text-primary">
                平台接入流程即将在3月15日上线，这段时间如有需求请联系我们，唐古远志中药处方流转平台技术支持人员将支持完成接入服务。
            </div>

            <h3 >一、入驻成为开发者</h3>
            <p className="p">
                使用唐古远志中药处方流转平台的任何能力和产品首先要入驻成为唐古远志中药处方流转平台开发者。
            </p>

            <ul className="pl-4" style={{ listStyle: 'decimal' }}>
                <li >
                    <h6>
                        打开唐古远志中药处方流转平台首页，点击右上角注册。
                    </h6>
                    <p>

                    </p>
                </li>
                <li >
                    <h6>
                        注册完成后，必须等待平台审核。
                    </h6>
                    <p>

                    </p>
                </li>
                <li >
                    <h6>
                        账号审核通过后，使用平台账号登陆即可完成入驻。
                    </h6>
                    <p>

                    </p>
                </li>
            </ul>

            <h3 >二、创建应用</h3>
            <p className="p">
                唐古远志中药处方流转平台目前开放处方流转业务应用，后续将逐步开放流程追溯、快递查询、财务对账等应用。
            </p>

            <ul className="pl-4" style={{ listStyle: 'decimal' }}>
                <li >
                    <h6>
                        选择接入的应用，申请应用创建资质。
                    </h6>
                    <p>

                    </p>
                </li>
                <li >
                    <h6>
                        申请后等待平台审核资质。
                    </h6>
                    <p>

                    </p>
                </li>
                <li >
                    <h6>
                        审核通过进入应用管理，在概览页面查看API Key和Secret Key。
                    </h6>
                    <p>

                    </p>
                </li>
            </ul>


            <h3 >三、开发与测试</h3>
            <p className="p">
                <NavLink href="#/api" >查阅API文档</NavLink>
            </p>

            <h3 >四、发布上线</h3>
            <p className="p">
                应用开发测试完成后，即可上线。
            </p>

        </div>
    );
}

export default View;
