import React from 'react';
import {
    Button, ButtonGroup, Nav, ToggleButton, Navbar, NavDropdown, Form, FormControl, Carousel,
    Container,
    Card,
    Row,
    Col,
} from 'react-bootstrap';

import { config } from '../../../utils/common';


function View(props) {
    return (
        <div className="px-3 pb-5 pt-2">
            <h3 >
                平台简介
                <span className="tip-update text-secondary" >更新时间：{config.update}</span>
            </h3>
            <p className="p">
                唐古远志中药处方流转平台，致力于通过互联网开放平台的运营思路和技术手段，满足中药服务供需双方交易匹配、处方流转和流程追溯等需求，赋能智慧中药房和共享中药房等中药制备新业态的发展，共建行业合作生态。
            </p>
            <h3 >平台特点</h3>
            <ul className="pl-4" style={{listStyle: 'disc'}}>
                <li >
                    <h6>
                        一站式平台服务方案
                    </h6>
                    <p>
                    通过平台自助式的互联网接口，实现中药代煎代配业务的订单下达、处方流转、流程追溯、快递查询和财务对账等服务，一站式解决了中药供需双方的信息对接需求。
                    </p>
                </li>
                <li >
                    <h6>
                    开放的合作形式
                    </h6>
                    <p>
                    唐古远志中药处方流转平台依托唐古信息全国客户群和服务网络，开放全国各区域的企业和服务；平台不仅为合作伙伴实现基础的业务对接，也为合作伙伴提供更多的外部资源来满足供需双方的各种需求，共建行业合作生态。
                    </p>
                </li>
                <li >
                    <h6>
                    标准化的规范体系
                    </h6>
                    <p>
                    构建标准化的接口体系，满足处方传输、状态回传、快递对接、流程追溯等中药服务中全方位服务的需求。
                    </p>
                </li>
                <li >
                    <h6>
                    稳定的服务支撑
                    </h6>
                    <p>
                    平台使用互联网行业大数据开放平台的微服务技术框架，结合阿里云后台服务支撑，提升平台服务的稳定度、数据加密、高并发等关键指标。
                    </p>
                </li>
                <li >
                    <h6>
                    专业全面的服务支持
                    </h6>
                    <p>
                    通过唐古远志中药处方流转平台，你可以获得开发、调试等全方位的技术支持和点对点的技术服务，开放平台技术支持团队可通过微信、QQ、电话等远程支持的方式为开发者提供及时有效的技术支持服务。
                    </p>
                </li>
            </ul>
        </div>
    );
}

export default View;
