import React, { Component } from 'react';
import {
    Button, ButtonGroup, Nav, ToggleButton, Navbar, NavDropdown, Form, FormControl, Carousel,
    Container,
    Card,
    Row,
    Col,
} from 'react-bootstrap';

import tableRender from '../../../component/tableRender';
import { post } from '../../../utils/common';
import { format1 } from '../../../utils/time';
import ApplyAppDialog from './ApplyAppDialog';
import { put } from '../../../utils/mixstate';
import confirm from '../../../component/confirm';
import GetAppSecretDialog from './GetAppSecretDialog';

const states = [{
    id: -1,
    name: '停用'
},{
    id: 0,
    name: '待审核'
},{
    id: 1,
    name: '已审核'
},{
    id: 99,
    name: '审核失败'
},
// {
//     id: 2,
//     name: '已发布'
// },
];

const stateMap = {};

states.forEach( item => stateMap[item.id] = item.name)

class View extends Component {

    constructor(props){
        super(props);
    }

    state = {
        datas: [],
        row: [],
    }

    componentDidMount(){
        this.load();
    }

    load = () => {
        post('usersApplication/listMyUsersApplication', null , json => {
            this.setState({
                datas: (json && json.data) || []
            })
        });
        
    }

    applyApp = () => {
        put('time_apply_app', Date.now());
    }

    delApply = (row) => {
        confirm({
            title: '删除',
            message: '确认删除应用吗',
            handler: r => {
                if (r === 'yes'){
                    post('usersApplication/deleteUserApplication', { id: row.id }, json => {
                        if (json.errorMsg) {
                            toast({
                                title: '错误',
                                message: json.errorMsg
                            })
                            return;
                        } else if (json.feedbackMsg) {
                            toast({
                                type: 'info',
                                message: json.feedbackMsg
                            });
                        }
                        this.load();
                        
                    })
                }
            }
        });

    }

    getAppSecret = (row) => {
        put('get_appsecret_time', Date.now());
        this.setState({ row })
        console.log('aaa', row);
    }

    // release = (row) => {
    //     post('', { id: row.id }, json => {
    //         if (json.errorMsg) {
    //             toast({
    //                 title: '错误',
    //                 message: json.errorMsg
    //             })
    //             return;
    //         } else if (json.feedbackMsg) {
    //             toast({
    //                 type: 'info',
    //                 message: json.feedbackMsg
    //             });
    //         }
    //         this.load(); 
    //     })
    // }

    columns = [{
        title: '产品名称',
        field: 'applicationName'
    }, {
        title: '应用名称',
        field: 'name'
    }, {
        title: 'AppKey',
        width: 30,
        field: 'apiKey'
    }, 
    {
        title: 'AppSecret',
        width: 30,
        render: (v, row) => {
            return <>
                <span> {row.secretKey} </span>
                {
                    row.secretKey ? <Button variant="link" size="sm" onClick={ () => this.getAppSecret(row)}>获取</Button> : ''
                }  
            </>
        },
        field: 'secretKey'
    }, 
    {
        title: '申请时间',
        render: v => v ? format1(v) : null,
        field: 'createTime'
    }, {
        title: '状态',
        render: v => stateMap[v],
        field: 'state'
    }, {
        title: '操作',
        width: 12,
        render: (v, row) => {
            return <>
                <Button href="#/api" variant="link" size="sm" >查看文档</Button>
                {
                    row.state === 1 ? '' : <Button variant="link" size="sm" onClick={ () => this.delApply(row)} style={{color: 'red'}}>删除</Button>
                } 
                {/* <Button variant="link" size="sm" onClick={ () => this.release(row)} >{row.state === 1 ? '发布' : ''}</Button> */}
            </>
        },
        field: 'operate'
    }]
    
    render(){

        return (
            <>
                <div className="py-3">
                    <Button onClick={ this.applyApp } >立即申请</Button>
                </div>
                <div className="pb-5">
                    {
                        tableRender(this.columns, this.state.datas, { empty: <div className="text-center p-3">没有数据</div>})
                    }
                </div>
                <ApplyAppDialog onApplyed={this.load} />
                <GetAppSecretDialog row={this.state.row}/>
            </>
        );
    }
}

export default View;
