import React from 'react';
import {
    Button, ButtonGroup, Nav, ToggleButton, Navbar, NavDropdown, Form, FormControl, Carousel,
    Container,
    Card,
    Row,
    Col,
} from 'react-bootstrap';

import NavLink from '../../component/NavLink';

import loop1 from '../../images/loop1.jpg';

import src_宁波药材 from '../../images/client/宁波药材.png';
import src_三溪堂 from '../../images/client/三溪堂.png';
import src_英特药业 from '../../images/client/英特药业.png';
import src_中医药大学 from '../../images/client/中医药大学.png';


import src_微医 from '../../images/app/微医.png';
import src_白露 from '../../images/app/白露.png';
import src_微脉 from '../../images/app/微脉.jpg';

function View(props) {
    return (
        <>
            <Container className="px-0 shadow-sm" fluid>
                <Carousel>
                    <Carousel.Item>
                        <img alt=""
                            className="d-block w-100"
                            src={loop1}
                        />
                    </Carousel.Item>
                </Carousel>
            </Container>
            <main role="main">
                <Container>
                    <h2 className="display-4 text-center mt-5 pt-5 pb-4" >可以帮你做什么？</h2>
                    <Row >
                        <Col   style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                            <div className="panel" >
                                <span className="iconfont icon-logo icon-shangwu"></span>
                                <div className="title">
                                    商务对接
                                </div>
                            </div>
                        </Col>
                        <Col   >
                            <div className="panel">
                                <span className="iconfont icon-logo icon-chufangliuzhuan"></span>
                                <div className="title">
                                    处方流转
                                </div>
                            </div>
                        </Col>
                        <Col   >
                            <div className="panel">
                                <span className="iconfont icon-logo icon-liucheng"></span>
                                <div className="title">
                                    流程追溯
                                </div>
                            </div>
                        </Col>
                        <Col   >
                            <div className="panel">
                                <span className="iconfont icon-logo icon-kuaidisudibaoguo"></span>
                                <div className="title">
                                    快递查询
                                </div>
                            </div>
                        </Col>
                        <Col   >
                            <div className="panel">
                                <span className="iconfont icon-logo icon-duizhang"></span>
                                <div className="title">
                                    财务对账
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <h2 className="display-4 text-center mt-5 pt-5 pb-4">服务对象</h2>
                    <Row >
                        <Col   style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                            <div className="panel d-flex flex-column justify-content-center" style={{ backgroundColor: '#2E9CEC', color: 'white' }} >
                                <h4 className="mt-2 ">
                                    业务需求方
                                </h4>
                            </div>
                        </Col>
                        <Col   >
                            <div className="panel">
                                <span className="iconfont icon-logo icon-yiyuan"></span>
                                <div className="title">
                                    医院
                                </div>
                            </div>
                        </Col>
                        <Col   >
                            <div className="panel">
                                <span className="iconfont icon-logo icon-yiguanweixuanzhong"></span>
                                <div className="title">
                                    医馆
                                </div>
                            </div>
                        </Col>
                        <Col   >
                            <div className="panel">
                                <span className="iconfont icon-logo icon-hulianwangyiyuan"></span>
                                <div className="title">
                                    互联网医院
                                </div>
                            </div>
                        </Col>
                        <Col   >
                            <div className="panel">
                                <span className="iconfont icon-logo icon-pingtai"></span>
                                <div className="title">
                                    在线<br />诊疗平台
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row >
                        <Col   style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                            <div className="panel d-flex flex-column justify-content-center" style={{ backgroundColor: '#2E9CEC', color: 'white' }} >
                                <h4 className="mt-2">
                                    药品供应
                                <br />
                                    及服务方
                                </h4>
                            </div>
                        </Col>
                        <Col   >
                            <div className="panel">
                                <span className="iconfont icon-logo icon-yaoqiguanli"></span>
                                <div className="title">
                                    药企
                                </div>
                            </div>
                        </Col>
                        <Col   >
                            <div className="panel">
                                <span className="iconfont icon-logo icon-zhongyao"></span>
                                <div className="title">
                                    煎药中心
                                </div>
                            </div>
                        </Col>
                        <Col   >
                            <div className="panel">
                                <span className="iconfont icon-logo icon-zhongyaopeisong"></span>
                                <div className="title">
                                    智慧中药房
                                </div>
                            </div>
                        </Col>
                        <Col   >
                            <div className="panel">
                                <span className="iconfont icon-logo icon-zhongyaobaohupinzhongchushenwangshangshenqingyushen"></span>
                                <div className="title">
                                    医共体<br />共享中药房
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <h2 className="display-4 text-center mt-5 pt-5 pb-4">开发支持</h2>
                    <Row >
                        <Col sm={true} _hide="xs" >

                        </Col>
                        <Col    >
                            <div className="panel">
                                <a href="#/api/4" className="c">
                                    <span className="iconfont icon-logo icon-wendang"></span>
                                    <div className="title">
                                        接口文档
                                    </div>
                                </a>
                            </div>
                        </Col>
                        <Col    >
                            <div className="panel">
                                <a href="#/api/download" className="c">
                                    <span className="iconfont icon-logo icon-ceshi"></span>
                                    <div className="title">
                                        测试工具
                                    </div>
                                </a>
                            </div>
                        </Col>
                        <Col    >
                            <div className="panel">
                                <span className="iconfont icon-logo icon-tiaoshi"></span>
                                <div className="title">
                                    API调试
                                </div>
                            </div>
                        </Col>
                        <Col   _hide="xs" >

                        </Col>
                    </Row>

                    <h2 className="display-4 text-center mt-5 pt-5 pb-4">接入流程</h2>

                    <div className="process p-4 shadow-sm bg-white text-center">

                        <div className="d-flex flex-row">
                            <div className="flex-grow-1 d-flex flex-column flex1">
                                <div className="d-flex flex-row">
                                    <hr className="flex-grow-1" style={{ borderColor: 'transparent' }} />
                                    <div className="text-center align-self-center rounded-circle bg-primary" style={{ width: '1.8em', height: '1.8em', lineHeight: '1.8em', color: 'white' }} >1</div>
                                    <hr className="flex-grow-1" />
                                </div>
                                <h5 className="text-center my-3">
                                    注册/登录
                                </h5>
                                <p className="info text-secondary px-3" >
                                    注册填写个人信息，成为平台用户
                                </p>
                            </div>

                            <div className="flex-grow-1 d-flex flex-column flex1">
                                <div className="d-flex flex-row">
                                    <hr className="flex-grow-1" />
                                    <div className="text-center align-self-center rounded-circle bg-primary" style={{ width: '1.8em', height: '1.8em', lineHeight: '1.8em', color: 'white' }} >2</div>
                                    <hr className="flex-grow-1" />
                                </div>
                                <h5 className="text-center my-3">
                                    等待通过
                                </h5>
                                <p className="info text-secondary px-3" >
                                    注册后，待平台审核资料
                                </p>
                            </div>

                            <div className="flex-grow-1 d-flex flex-column flex1">
                                <div className="d-flex flex-row">
                                    <hr className="flex-grow-1" />
                                    <div className="text-center align-self-center rounded-circle bg-primary" style={{ width: '1.8em', height: '1.8em', lineHeight: '1.8em', color: 'white' }} >3</div>
                                    <hr className="flex-grow-1" />
                                </div>
                                <h5 className="text-center my-3">
                                    创建应用
                                </h5>
                                <p className="info text-secondary px-3" >
                                    创建我的应用，待平台审核申请
                                </p>
                            </div>

                            <div className="flex-grow-1 d-flex flex-column flex1">
                                <div className="d-flex flex-row">
                                    <hr className="flex-grow-1" />
                                    <div className="text-center align-self-center rounded-circle bg-primary" style={{ width: '1.8em', height: '1.8em', lineHeight: '1.8em', color: 'white' }} >4</div>
                                    <hr className="flex-grow-1" />
                                </div>
                                <h5 className="text-center my-3">
                                    接口配置
                                </h5>
                                <p className="info text-secondary px-3" >
                                    自主调试API接口进行测试
                                </p>
                            </div>
                            <div className="flex-grow-1 d-flex flex-column flex1">
                                <div className="d-flex flex-row">
                                    <hr className="flex-grow-1" />
                                    <div className="text-center align-self-center rounded-circle bg-primary" style={{ width: '1.8em', height: '1.8em', lineHeight: '1.8em', color: 'white' }} >5</div>
                                    <hr className="flex-grow-1" style={{ borderColor: 'transparent' }} />
                                </div>
                                <h5 className="text-center my-3">
                                    完成
                                </h5>
                                <p className="info text-secondary px-3" >
                                    测试通过后即可
                                </p>
                            </div>
                        </div>
                    </div>

                    <h2 className="display-4 text-center mt-5 pt-5 pb-4">入驻煎药中心</h2>
                    <Row >
                        <Col xs={3} >
                            <img alt="" className="shadow-sm" src={src_中医药大学} style={{ width: '100%' }} ></img>
                        </Col>
                        <Col xs={3}>
                            <img alt="" className="shadow-sm" src={src_英特药业} style={{ width: '100%' }} ></img>
                        </Col>
                        <Col xs={3} >
                            <img alt="" className="shadow-sm" src={src_宁波药材} style={{ width: '100%' }} ></img>
                        </Col>
                        <Col xs={3} >
                            <img alt="" className="shadow-sm" src={src_三溪堂} style={{ width: '100%' }} ></img>
                        </Col>
                    </Row>
                    <div className="text-center m-3"><NavLink href="#/friends">查看更多服务资源</NavLink></div>

                    <h2 className="display-4 text-center mt-5 pt-5 pb-4">典型案例</h2>
                    <Row >
                        <Col xs={{ span: 3 }} style={{marginLeft: '12.455555%'}} >
                            <img alt="" className="shadow-sm w-100" src={src_微医} ></img>
                        </Col>
                        <Col xs={3} >
                            <img alt="" className="shadow-sm w-100" src={src_白露} ></img>
                        </Col>
                        <Col xs={3} >
                            <img alt="" className="shadow-sm w-100" src={src_微脉} ></img>
                        </Col>
                    </Row>
                    
                </Container>
            </main>
            <a href="http://www.wenjuan.com/s/J7vyEf5" className="conatact shadow-sm bg-danger rounded-lg">
                <span className="conatact-icon iconfont icon-contact" ></span>
                <div className="conatact-text">
                    申请加入
                </div>
            </a>
        </>
    );
}

export default View;
