
var slice = Array.prototype.slice;

function eventSorter(e1, e2) {
    return e1.seq - e2.seq;
}

export class Event {
    constructor(cfg) {
        Object.assign(this, {
            seq: 0,
            ...cfg
        });
    }
}

export default class Events {

    constructor(cfg) {
        Object.assign(this, cfg);
    }

    on(event) {
        var _events = (this._events = this._events || {}),
            events = (_events[event.name] = _events[event.name] || []), i, b;
        if (!event instanceof Event) {
            event = new Event(event);
        }
        for (i = 0; (b = events[i]) && (b.seq <= event.seq); i += 1) {
            if (b === event) {
                return this;
            }
        }
        events.splice(i, 0, event);
        return this;
    }

    off(event) {
        var _events = (this._events = this._events || {}),
            events = (_events[event.name] = _events[event.name] || []);
        let index = events.indexOf(event);
        if (index > -1) {
            events.splice(index, 1);
        }
        return this;
    }

    onEvents(events){
        if (events) {
            for (var i = 0, b; (b = events[i]); i += 1) {
                this.on(b);
            }
        }
    }

    offEvents(events) {
        if (events) {
            for (var i = 0, b; (b = events[i]); i += 1) {
                this.off(b);
            }
        }
    }

    trigger(name) {
        var args = slice.call(arguments, 1),
            _events = (this._events = this._events || {}),
            events = (_events[name] = _events[name] || []),
            i, event;
        if (!events) {
            return true;
        }
        switch (args.length) {
            case 0:
                for (i = 0; (event = events[i]); i += 1) {
                    if (event.handler.call(event.context || this) === false) {
                        return false;
                    }
                }
                break;
            case 1:
                for (i = 0; (event = events[i]); i += 1) {
                    if (event.handler.call(event.context || this, args[0]) === false) {
                        return false;
                    }
                }
                break;
            case 2:
                for (i = 0; (event = events[i]); i += 1) {
                    if (event.handler.call(event.context || this, args[0], args[1]) === false) {
                        return false;
                    }
                }
                break;
            case 3:
                for (i = 0; (event = events[i]); i += 1) {
                    if (event.handler.call(event.context || this, args[0], args[1], args[2]) === false) {
                        return false;
                    }
                }
                break;
            default:
                for (i = 0; (event = events[i]); i += 1) {
                    if (event.handler.apply(event.context || this, args) === false) {
                        return false;
                    }
                }
                break;
        }
        return true;
    }

    getEvents = function () {
        return this._events || {};
    }

}

Events.Event = Event;

let events_prototype = {
    on: Events.prototype.on,
    off: Events.prototype.off,
    trigger: Events.prototype.trigger
}

Events.attach = function (target) {
    Object.assign(target, events_prototype);
}

Events.mix = function (clazz) {
    Object.assign(clazz.prototype, events_prototype);
}

Events.attach(Events);
