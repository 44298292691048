import React, { Component } from 'react';
import {
    Button, ButtonGroup, Nav, ToggleButton, Navbar, NavDropdown, Form, FormControl, Carousel,
    Container,
    Card,
    Row,
    Col,
    Modal,
    InputGroup,

} from 'react-bootstrap';

import { post, initLogindata } from '../../utils/common';
import { put, build } from '../../utils/mixstate';

var mixer = build(['login_time_login']);

export default class View extends Component {

    constructor(props) {
        super(props);
        this.state = {
            visible: false
        };
        mixer.mix(this);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.login_time_login !== prevState.login_time_login && this.state.login_time_login) {
            this.setState({
                visible: true,
                password: undefined
            });
        }
    }

    componentDidMount() {
        window.addEventListener('keypress', this.listenKeypress.bind(this))
    }

    componentWillUnmount() {
        window.removeEventListener('keypress',this.listenKeypress.bind(this));
    }

    listenKeypress = (e) => {
        if(e.keyCode == 13 || e.which == 13 && this.validate()) {
            this.login();
        }
    }

    onHide = () => {
        this.setState({
            visible: false
        });
    }

    login = () => {
        post('auth', {
            username: this.state.username,
            password: this.state.password
        }, res => {
                        // console.log(res);
            if (res.errorMsg) {
                toast({
                    title: '错误',
                    message: res.errorMsg
                })
                return;
            } else if (res.feedbackMsg) {
                toast({
                    type: 'info',
                    message: res.feedbackMsg
                });
            }
            var logindata = {
                roleId: res.roleId,
                token: res.token.body,
                username: res.username,
            }
            initLogindata(logindata);
            this.onHide();
            this.setState({
                password: undefined
            })
            
        }, (err, res) => {
            if (this.clearError){
                clearTimeout(this.clearError);
                this.clearError = null;
            }
            var error = res.body && (res.body.feedbackMsg || res.body.errorMsg);
            
            this.setState({
                error: error
            });
            this.clearError = setTimeout( () => {
                this.setState({
                    error: null
                }); 
                this.clearError = null;
            }, 5000);
        })
    }

    regist = () => {
        this.onHide();
        put('login_time_regist', Date.now());
    }

    formChagne = (field, value) => {
        this.setState({
            [field]: value
        })
    }

    validate = () => {
        return this.state.password && this.state.username;
    }


    render() {

        return <Modal show={this.state.visible} onHide={this.onHide} centered >
            <span className="dialog-close-button iconfont icon-guanbi" onClick={this.onHide} ></span>
            <Modal.Body>
                <div className="mx-5 my-4">
                    <h4 className="text-center mb-5 font-weight-bold">
                        <span className="border-primary text-bold px-3 pb-2" style={{ borderBottom: '4px solid blue' }}>用户登录</span>
                    </h4>
                    <InputGroup className="mb-4"  >
                        <InputGroup.Prepend>
                            <InputGroup.Text id="basic-addon1"><span className="iconfont icon-yonghu"></span></InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                            placeholder="手机号"
                            aria-label="手机号"
                            aria-describedby="手机号"
                            value={this.state.username}
                            onChange={e => this.formChagne('username', e.target.value)}
                        />
                    </InputGroup>
                    <InputGroup className="mb-0"  >
                        <InputGroup.Prepend>
                            <InputGroup.Text id="basic-addon1"><span className="iconfont icon-mima"></span></InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                            placeholder="密码"
                            aria-label="密码"
                            aria-describedby="密码"
                            as="input"
                            type="password"
                            value={this.state.password}
                            onChange={e => this.formChagne('password', e.target.value)}
                        />
                    </InputGroup>
                    <p className="text-danger mb-4" style={{ fontSize: '12px', height: '1em' }}>
                        {this.state.error}
                    </p>
                    <p className="text-secondary">没有唐古账号？现在去<span onClick={this.regist} className="text-danger" style={{cursor:"pointer"}} >注册</span></p>
                    <Button block variant="primary" onClick={this.login} disabled={!this.validate()}>登录</Button>
                </div>
            </Modal.Body>
        </Modal>
    }

}

