import React, { Component } from 'react';
import {
    Form,
} from 'react-bootstrap';


/**
 * reset 重置 valdiate
 * 
 */
export default class FormItem extends Component {

    constructor(props) {
        super(props);
        this.state = {
            valid: false,
            changed: false,
        };
        this.value = this.getDefaultValue(props);
    }

    componentDidMount() {
        var ctx;
        if ((ctx = this.props.context) && this.props.statename) {
            (ctx[this.props.statename + '_fields'] || (ctx[this.props.statename + '_fields'] = [])).push(this);
        }
        this.loadFormForm();
        this.validateform();
    }

    componentWillUnmount() {
        var ctx;
        if ((ctx = this.props.context) && this.props.statename) {
            ctx[this.props.statename + '_fields'] = ctx[this.props.statename + '_fields'] ? ctx[this.props.statename + '_fields'].filter(item => item === this) : [];
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.reset !== this.props.reset && this.props.reset) {
            this.setState({
                valid: false,
                changed: false
            });
        }
    }

    loadFormForm = () => {
        var value = this.getDefaultValue(this.props);
        console.log(value);
        value = value === undefined || value === null ? "" : value;
        if (this.value !== value) {
            this.value = value;
            if (this._el) {
                this._el.value = this.value ;
            } 
        }
    }

    onChange = (e) => {
        this._el = e.target;
        var value = this.value = e.target.value;
        if (this.props.context && this.props.statename) {
            var datas = this.props.context.state && this.props.context.state[this.props.statename] || {};
            datas[this.props.name] = value;
            this.props.context.setState({
                [this.props.statename]: datas
            });
        }
        this.props.onChange && this.props.onChange(value, this);
        this.setState({
            changed: true
        });
        this.validateform();
    }

    validateform() {
        var ctx;
        if ((ctx = this.props.context) && this.props.statename) {
            var fields = ctx[this.props.statename + '_fields'];
            if (fields && fields.length) {
                var valid = true;
                fields.forEach(field => {
                    valid = field.validate() && valid;
                });
                ctx.setState({
                    [this.props.statename + '_valid']: valid
                });
            }
        }
    }

    validate = () => {
        var value = this.value, valid = true, message;
        if (this.props.required && (value === undefined || value === null || value === '')) {
            valid = false;
            message = '不能为空';
        } else if (this.props.validate) {
            message = this.props.validate(value);
            if (message) {
                valid = false;
            }
        } else if (this._el && this._el.validity && !this._el.validity.valid) {
            valid = false;
        }
        this.setState({
            valid,
            message
        });

        var ctx;
        if ((ctx = this.props.context) && this.props.statename) {
            ctx.setState({
                [this.props.statename + '_valid.' + this.props.name]: valid
            });
            // console.log(ctx.state);
        }

        return valid;
    }

    getDefaultValue(props) {
        return props.context && props.context.state && props.context.state[props.statename] && props.context.state[props.statename][props.name];
    }

    render() {
        // console.log(this.props.name , this.value);
        return <Form.Control {...this.props} autoComplete="off" isInvalid={this.state.changed & !this.state.valid} isValid={this.state.changed & this.state.valid} defaultValue={this.getDefaultValue(this.props)} value={this.value} onChange={this.onChange} >{this.props.children}</Form.Control>
    }

}

FormItem.mix = (ctx) => {
    ctx.$validate = (statename) => {
        if (statename) {
            var fields = ctx[statename + '_fields'];
            if (fields && fields.length) {
                var valid = true;
                fields.forEach(field => {
                    valid = field.validate() && valid;
                });
                ctx.setState({
                    [statename + '_valid']: valid
                });
            }
        }
    }
    ctx.$setFormData = (statename, datas) => {
        // console.log(statename, datas)
        ctx.setState({
            [statename]: datas
        }, () => {
            var fields = ctx[statename + '_fields'];
            // console.log(fields);
            if (fields) {
                fields.forEach(item => {
                    item.loadFormForm();
                    item.setState({});
                });
            }
            ctx.$validate(statename);
        });
    }
}