import React from 'react';
import {
    Button, ButtonGroup, Nav, ToggleButton, Navbar, NavDropdown, Form, FormControl, Carousel,
    Container,
    Card,
    Row,
    Col,
} from 'react-bootstrap';

import {
    HashRouter as Router,
    Switch,
    Route,
    useRouteMatch
} from "react-router-dom";

import IntroView from './subs/IntroView';
import ForView from './subs/ForView';
import JoinView from './subs/JoinView';

import NavLink from '../../component/NavLink';

import src_bar_doc from '../../images/bar_docs.jpg';

var links = [{
    url: `/`,
    name: '平台简介',
    exact: true,
    view: IntroView
}, {
    url: `/for`,
    name: '应用场景',
    view: ForView
}, {
    url: `/join`,
    name: '快速接入',
    view: JoinView
}]

function View(props) {
    var { path, url } = useRouteMatch();
    return (
        <>
            <Container className="p-0 d-flex align-items-center flex-column justify-content-center" fluid style={{backgroundImage: `url("${src_bar_doc}")`, height: 300, color: 'rgba(255,255,255,1)', textShadow: '0 0 10px blue'}}>
                <h1 className="mb-4 font-weight-bold" style={{paddingTop: 60}}>
                文档中心与帮助
                </h1>
                <h4 className="mt-3 mb-3">
                开放的合作形式，融入行业应用场景，共建合作生态
                </h4>
            </Container>
            <Container className="border bg-white my-3" style={{ minHeight: '70%' }} >
                <Row>
                    <Col xs={2} >
                        <Navbar sticky='top' className="p-0" style={{top: 59}} >
                            <Nav className="flex-column tree-menu py-3">
                                {links.map( item => {
                                    return <NavLink exact={item.exact} href={`#${path}${item.url}`} key={item.url}>{item.name}</NavLink>;
                                })}
                            </Nav>
                        </Navbar>
                    </Col>
                    <Col className="border-left">
                        <div className="doc px-3 pb-3 pt-0" >
                            <Switch>
                                {links.map( (item, index) => {
                                    return <Route exact={item.exact} path={`${path}${item.url}`} key={item.url}>
                                        <item.view />
                                    </Route>;
                                })}
                            </Switch>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default View;
