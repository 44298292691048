import React, { Component } from 'react';
import {
    Button, ButtonGroup, Nav, ToggleButton, Navbar, NavDropdown, Form, FormControl, Carousel,
    Container,
    Card,
    Row,
    Col,
} from 'react-bootstrap';

import {
    HashRouter as Router,
    Switch,
    Route,
    useRouteMatch
} from "react-router-dom";

import ProfileView from './subs/ProfileView';
import AppsView from './subs/AppsView';
import DockingView from './subs/DockingView';

import NavLink from '../../component/NavLink';

import src_bar_doc from '../../images/bar_docs.jpg';
import { build } from '../../utils/mixstate';

var links = [{
    url: `/`,
    name: '我的信息',
    exact: true,
    view: ProfileView
}, {
    url: `/apps`,
    name: '我的应用',
    view: AppsView
},{
    url: `/dock`,
    name: '机构列表',
    view: DockingView
}];

var mixer = build(['logindata']);

class View extends Component{

    constructor(props){
        super(props);
        mixer.mix(this);
    }

    componentDidMount(){
        this.checklogin();
    }

    componentDidUpdate(prevProps, prevState){  
        if (prevState.logindata !== this.state.logindata){
            this.checklogin();
        }
    }

    checklogin(){
        var logindata = this.state.logindata;
        if (!logindata || !logindata.username){
            location = '#';
        }
    }

    render(){
        var path = this.props.path;
        return (
            <>
                <div></div>
                <Container className="p-0 d-flex align-items-center flex-column justify-content-center" fluid>
                    <Container className="mt-3">
                        <h2 className="text-center" style={{margin:'2em 0 0.5em 0'}}>接入流程</h2>
                        <div className="process text-center">
                            <div className="d-flex flex-row">
                                <div className="flex-grow-1 d-flex flex-column flex1">
                                    <div className="d-flex flex-row">
                                        <hr className="flex-grow-1" style={{ borderColor: 'transparent' }} />
                                        <div className="text-center align-self-center rounded-circle bg-primary" style={{ width: '1.8em', height: '1.8em', lineHeight: '1.8em', color: 'white' }} >1</div>
                                        <hr className="flex-grow-1 bg-white" />
                                    </div>
                                    <h5 className="text-center my-3">
                                        申请应用
                                    </h5>
                                    <p className="info px-3" >
                                        申请应用,选择产品API
                                    </p>
                                </div>

                                <div className="flex-grow-1 d-flex flex-column flex1">
                                    <div className="d-flex flex-row">
                                        <hr className="flex-grow-1 bg-white" />
                                        <div className="text-center align-self-center rounded-circle bg-primary" style={{ width: '1.8em', height: '1.8em', lineHeight: '1.8em', color: 'white' }} >2</div>
                                        <hr className="flex-grow-1 bg-white" />
                                    </div>
                                    <h5 className="text-center my-3">
                                        等待审核通过
                                    </h5>
                                    <p className="info px-3" >
                                        应用审核成功后,密钥将发送至邮箱
                                    </p>
                                </div>

                                <div className="flex-grow-1 d-flex flex-column flex1">
                                    <div className="d-flex flex-row">
                                        <hr className="flex-grow-1 bg-white" />
                                        <div className="text-center align-self-center rounded-circle bg-primary" style={{ width: '1.8em', height: '1.8em', lineHeight: '1.8em', color: 'white' }} >3</div>
                                        <hr className="flex-grow-1 bg-white" />
                                    </div>
                                    <h5 className="text-center my-3">
                                        申请机构
                                    </h5>
                                    <p className="info px-3" >
                                        申请机构,输入医疗机构、申请用途
                                    </p>
                                </div>

                                <div className="flex-grow-1 d-flex flex-column flex1">
                                    <div className="d-flex flex-row">
                                        <hr className="flex-grow-1 bg-white" />
                                        <div className="text-center align-self-center rounded-circle bg-primary" style={{ width: '1.8em', height: '1.8em', lineHeight: '1.8em', color: 'white' }} >4</div>
                                        <hr className="flex-grow-1 bg-white" />
                                    </div>
                                    <h5 className="text-center my-3">
                                        等待审核通过
                                    </h5>
                                    <p className="info px-3" >
                                        等待机构申请审核通过
                                    </p>
                                </div>
                                <div className="flex-grow-1 d-flex flex-column flex1">
                                    <div className="d-flex flex-row">
                                        <hr className="flex-grow-1 bg-white" />
                                        <div className="text-center align-self-center rounded-circle bg-primary" style={{ width: '1.8em', height: '1.8em', lineHeight: '1.8em', color: 'white' }} >5</div>
                                        <hr className="flex-grow-1" style={{ borderColor: 'transparent' }} />
                                    </div>
                                    <h5 className="text-center my-3">
                                        完成
                                    </h5>
                                    <p className="info px-3" >
                                        接入成功
                                    </p>
                                </div>
                            </div>
                        </div>
                    </Container>
                </Container>
                
                <Container className="border bg-white mt-3 border-bottom-0" >
                    <div style={{padding: '1em'}}>
                        我的
                    </div>
                </Container>
                <Container className="border bg-white mb-3" >
                    <Row style={{ minHeight: '55vh' }}>
                        <Col xs={2} >
                            <Navbar sticky='top' className="p-0" style={{top: 59}} >
                                <Nav className="flex-column tree-menu py-3">
                                    {links.map( item => {
                                        return <NavLink exact={item.exact} href={`#${path}${item.url}`} key={item.url}>{item.name}</NavLink>;
                                    })}
                                </Nav>
                            </Navbar>
                        </Col>
                        <Col className="border-left" >
                            <div className="doc px-3 pb-3 pt-0">
                                <Switch>
                                    {links.map( (item, index) => {
                                        return <Route exact={item.exact} path={`${path}${item.url}`} key={item.url}>
                                            <item.view />
                                        </Route>;
                                    })}
                                </Switch>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </>
        );

    }

}

function render(props) {
    var { path, url } = useRouteMatch();
    return <View path={path} />
}

export default render;
