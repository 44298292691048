import React from 'react';
import {
    Button, ButtonGroup, Nav, ToggleButton, Navbar, NavDropdown, Form, FormControl, Carousel,
    Container,
    Card,
    Row,
    Col,
} from 'react-bootstrap';

import { config } from '../../../utils/common';

import src_需求方 from '../../../images/catalog/需求方.png';
import src_供应方 from '../../../images/catalog/供应方.png';

function View(props) {
    return (
        <div className="px-3 pb-5 pt-2">
            <h3 >
                应用场景
                <span className="tip-update text-secondary" >更新时间：{config.update}</span>
            </h3>
            <p className="p">
                医院、医馆等医疗机构、互联网医院、在线诊疗平台可以通过唐古远志中药处方流转平台快速接入全国各地的中药药企、煎药中心、智慧中药房和医共体共享中药房，实现中药代煎代配业务的处方流转，提升业务效率，降低运营成本。
            </p>
            <h3 >业务需求方</h3>
            <p className="p">
            医院、医馆等医疗机构、互联网医院、在线诊疗平台等业务需求方，将电子处方上传唐古远志中药处方流转平台。
            </p>
            <div className="m-3" >
                <img src={src_需求方} alt="" style={{width: '100%'}}></img>
            </div>
            <ul className="pl-4" style={{listStyle: 'decimal'}}>
                <li >
                    <h6>
                    医院、医馆等医疗机构
                    </h6>
                    <p>
                    医生在医疗机构线下坐诊，患者到医疗机构就诊后，医生使用医疗机构的信息管理系统开具处方；医疗机构事先约定合作的药品供应服务方，信息系统指定处方流转供应方并将处方上传唐古远志中药处方流转平台；平台只允许该供应方下载对应的处方。
                    </p>
                </li>
                <li >
                    <h6>
                    互联网医院和在线诊疗平台
                    </h6>
                    <p>
                    患者通过互联网医院或在线诊疗平台，向医生咨询、问诊，医生使用线上平台开具处方；根据互联网医院或在线诊疗平台的实际业务情况，存在事先约定合作的药品供应服务方、医生（或患者）自主选择药品供应服务方两种模式；线上平台指定处方流转供应方并将处方上传唐古远志中药处方流转平台；平台只允许该供应方下载对应的处方。
                    </p>
                </li>                
            </ul>

            <h3 >药品供应及服务方</h3>
            <p className="p">
            中药药企、煎药中心、智慧中药房和医共体共享中药房等药品供应及服务方，从唐古远志中药处方流转平台下载电子处方，进行处方审核、调配、煎煮流程。需求方已指定处方流转的供应服务方，对应的供应服务方只能下载自己的电子处方。
            </p>
            <div className="m-3" >
                <img src={src_供应方} alt="" style={{width: '100%'}}></img>
            </div>
            <ul className="pl-4" style={{listStyle: 'decimal'}}>
                <li >
                    <h6>
                    药企、煎药中心
                    </h6>
                    <p>
                    药企、煎药中心为需求方提供代煎、代配服务；企业内部的业务系统从唐古远志中药处方流转平台定向下载电子处方，对处方进行审核、调配、煎煮流程加工，并使用业务系统做到流程记录可追溯。
                    </p>
                </li>
                <li >
                    <h6>
                    智慧中药房
                    </h6>
                    <p>
                    智慧中药房分为院内智慧中药房和院外智慧中药房；不论哪种模式，处方下载场景是相同的；智慧中药房的业务平台从唐古远志中药处方流转平台定向下载电子处方，对配药处方进行调剂，对煎药处方调配、煎煮等相关流程操作；
                    </p>
                </li>    
                <li >
                    <h6>
                    医共体共享中药房
                    </h6>
                    <p>
                    医共体内的医院将处方上传唐古远志中药处方流转平台，医共体平台从唐古远志中药处方流转平台定向下载电子处方；根据医共体实际业务情况，对处方进行调配煎煮或者将处方分流到合作的药企。
                    </p>
                </li>               
            </ul>
        </div>
    );
}

export default View;
