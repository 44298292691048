
import Events from './events';

const cache_state = {}, cache_init = {}, pub = new Events();

function mix(target){
    
    var events = this;

    for (var i=0,b;(b=events[i]);i+=1){
        b.context = target;
    }
    
    events._mounted = target.componentDidMount;
    target.componentDidMount = events.mounted;

    events._unmounted = target.componentWillUnmount;
    target.componentWillUnmount = events.unmounted;

    Object.assign(target.state = target.state || {}, events._buildState());

}

function depose(){
    pub.offEvents(this);
}

/**
 * 设置默认值
 * @param { 数据名称 } name 
 * @param { 数据值 } value 
 */
export const init = (name, value) => {
    cache_init[name] = value;
    if (cache_state[name] === undefined && value !== undefined){
        put(name, value);
    }
}

/**
 * 设置数据值，如果 value 为 undefined , 会初始化为默认值 
 * @param { 数据名称 } name 
 * @param { 数据值 } value 
 */
export const put = (name, value) => {
    pub.trigger(name, cache_state[name] = (value === undefined ? cache_init[name] : value) );
}

/**
 * 
 * 创建数据同步器
 * 
 * @param { 同步数据映射规则 } mappers 
 * 
 * @returns 数据流同步器， 
 *      mix 方法绑定 react 对象，应当在 constructor 方法中调用 mix 方法，
 *          会初始化 state 对象
 *      depose 方法，取消监听，同步器停止使用
 * 
 * 
 */
export const build = (mappers) => {

    mappers = mappers.map( item => {
        return typeof item === 'string' ? {
            from: item,
            to: item,
        } : item;
    });

    var handler = function(){
        
        if (!this._is_state_valid){
            return;
        }
        
        this.setState && this.setState(events._buildState());
    }
    
    var events = mappers.map( item => {
        return new Events.Event({
            name: item.from,
            handler
        });
    });

    events._buildState = () => {
        return mappers.reduce( (total, item) => {
            total[item.to] = cache_state[item.from];
            return total;
        }, {});
    }
    
    pub.onEvents(events);

    events.mounted = function(){
        events._mounted && events._mounted.call(this);
        this._is_state_valid = true;
    }

    events.unmounted = function(){
        this._is_state_valid = false;
        events._unmounted && events._unmounted.call(this);
    }

    events.mix = mix;
    events.depose = depose;

    return events;

}
