
var types = [{
    id: 1,
    name: '医疗机构'
}, {
    id: 2,
    name: '药企'
    // },{
    //     id: 99,
    //     name: '管理员'
}];

export default types;
