
import request from 'superagent';
import { put } from './mixstate';

var base = '/tos/';

var header = {};

export const config = {
    update: '2020-03-03'
}

export function post(url, data, success , fail ){
    url = url.startsWith('//') || url.startsWith('http') ? url : base + url;
    var req = request.post(url);
    header && req.set(header);
    req.set(header).send(data).end((error, res) => {
        var errorMsg = res.body && res.body.errorMsg || (error ? '网络异常，无法访问服务器' : '');
        if (errorMsg){
            fail && fail(errorMsg, res, error);
        } else {

            success && success(res.body, res);
        }
    });
}

export function get(url, success , fail ){
    url = url.startsWith('//') || url.startsWith('http') ? url : base + url;
    var req = request.get(url);
    header && req.set(header);
    req.end((error, res) => {
        var errorMsg = res.body && res.body.errorMsg || (error ? '网络异常，无法访问服务器' : '');
        if (errorMsg){

            fail && fail(errorMsg, res, error);
        } else {
            
            success && success(res.body, res);
        }
    });
}

var logindata;

if (window.localStorage){
    var _logindata = localStorage.getItem('logindata')
    logindata = _logindata ? JSON.parse(_logindata) : {};
    put('logindata', logindata);
    if (logindata.token){
        header.Authorization = 'Bearer '+logindata.token;
    } else {
        delete header.Authorization;
    }
} 

export const initLogindata = (_logindata) => {
    logindata = _logindata || {};
    put('logindata', logindata);
    if (logindata.token){
        header.Authorization = 'Bearer '+logindata.token;
    } else {
        delete header.Authorization;
    }
    if (window.localStorage){
        localStorage.setItem('logindata', JSON.stringify(logindata));
    }
}

var bind_add = window.addEventListener;
export function bind(target, event, handler) {
    if (bind_add) {
        target.addEventListener(event, handler);
    } else {
        target.attachEvent('on' + event, handler);
    }
}

export function off(target, event, handler) {
    if (bind_add) {
        target.removeEventListener(event, handler);
    } else {
        target.detachEvent('on' + event, handler);
    }
}

// fix nextElementSibling 属性
if(!("nextElementSibling" in document.documentElement)){
    Object.defineProperty(Element.prototype, "nextElementSibling", {
        get: function(){
            var e = this.nextSibling;
            while(e && 1 !== e.nodeType)
                e = e.nextSibling;
            return e;
        }
    });
}
