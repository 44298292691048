import React, { Component } from 'react';
import {
    Button, ButtonGroup, Nav, ToggleButton, Navbar, NavDropdown, Form, FormControl, Carousel,
    Container,
    Card,
    Row,
    Col,
    Modal,
    InputGroup,

} from 'react-bootstrap';

import { post, initLogindata, get } from '../../utils/common';
import { put, build } from '../../utils/mixstate';
// import { toast } from '../../App';
import FormItem from '../../component/FormItem';
import types from '../../datas/userTypes';

var mixer = build(['login_time_regist']);


export default class View extends Component {

    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            validated: false,
            datas: {}
        };
        mixer.mix(this);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.login_time_regist !== prevState.login_time_regist && this.state.login_time_regist) {
            this.setState({
                visible: true,
                validated: false,
                datas: {}
            });
        }
    }

    onHide = () => {
        this.setState({
            visible: false
        });
    }

    regist = () => {
        post('users/registry', this.state.datas, json => {
            if (json.errorMsg){
                toast({
                    message: json.errorMsg
                })
                return;
            } else if (json.feedbackMsg){
                toast({
                    type: 'info',
                    message: json.feedbackMsg
                });
            }
            this.onHide();

        }, err => {
            toast({
                message: err
            });
        })
        
    }

    refForm = () => {

    }

    validate() {
        return this.state.userPassword && this.state.username;
    }

    getVcode = () => {
        if (this.vcode_counter){
            return;
        }

        get('users/getVerifyCode/'+this.state.datas.userTel, (json = {}) => {
                        
            this.setState({
                vcode_counter: this.vcode_counter = 60
            });
            this.counter_handler = setInterval(() => {
                this.setState({
                    vcode_counter: this.vcode_counter -= 1
                });
                if (this.vcode_counter <= 0){
                    clearInterval(this.counter_handler);
                    this.counter_handler = undefined;
                    this.setState({
                        vcode_counter: undefined
                    });
                }
            }, 1000);

        }, err => {
            toast({
                message: err
            })
        })


    }

    render() {

        // console.log( this.state );

        return <Modal show={this.state.visible} onHide={this.onHide} centered backdrop="static" >
            <span className="dialog-close-button iconfont icon-guanbi" onClick={this.onHide} ></span>
            <Modal.Body className="mx-5 my-3">
                <div className="mb-3">
                    <h4 className="text-center mb-5 font-weight-bold">
                        <span className="border-primary text-bold px-3 pb-2" style={{ borderBottom: '4px solid blue' }}>用户注册</span>
                    </h4>
                </div>
                <Form ref={this.refForm } noValidate validated={false} >
                    <Form.Row>
                        <Form.Group as={Col} >
                            <FormItem statename="datas" context={this} type="text" placeholder="企业名称" required minLength="2"
                                name="companyName"
                            />
                            <Form.Control.Feedback type="invalid">
                                请输入企业名称 (至少为2个字符)
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} >
                            <FormItem statename="datas" context={this} type="text" placeholder="联系人" required minLength="2"
                                name="contact"
                                />
                            <Form.Control.Feedback type="invalid">
                                请输入联系人 (至少为2个字符)
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} >
                            <FormItem statename="datas" context={this} 
                                as="select" 
                                required 
                                name="roleId"
                            >
                                <option value="" >请选择账号类型</option>
                                {
                                    types.map( item => {
                                        return <option value={item.id}>{item.name}</option>
                                    })
                                }
                            </FormItem>
                            <Form.Control.Feedback type="invalid">
                                请选择账号类型
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} >
                            <FormItem statename="datas" context={this} type="email" placeholder="个人邮箱" required minLength="2"
                                name="email"
                                />
                                <Form.Control.Feedback type="invalid">
                                    邮箱格式错误,请重新输入
                                </Form.Control.Feedback>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} >
                            <FormItem statename="datas" context={this} type="text" placeholder="手机号码" required minLength="11"  maxLength="11"
                                name="userTel"
                            />
                            <Form.Control.Feedback type="invalid">
                                手机号码格式错误,请重新输入
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} >
                            <InputGroup >
                                <FormItem statename="datas" context={this} type="text" placeholder="验证码" 
                                    name="verifyCode"
                                    required
                                    minLength="6"
                                    maxLength="6"
                                />
                                <InputGroup.Append>
                                    <Button onClick={this.getVcode} className="block" style={{width: '9em'}} disabled={this.state.vcode_counter || !this.state['datas_valid.userTel']} >获取验证码{this.state.vcode_counter} </Button>
                                </InputGroup.Append>
                                <Form.Control.Feedback type="invalid">
                                    验证码错误
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} >
                            <FormItem statename="datas" context={this} type="password" placeholder="密码" required
                                name="userPassword" minLength="6"  maxLength="20"
                            />
                            <Form.Control.Feedback type="invalid">
                                密码为6-20个字符,请重新输入
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} >
                            <FormItem statename="datas" context={this} type="password" placeholder="确认密码" required
                                name="password2" minLength="6"  maxLength="20"
                                validate={ () => this.state.datas.userPassword !== this.state.datas.password2 ? '两次密码输入不一致' : '' }
                            />
                            <Form.Control.Feedback type="invalid">
                                两次密码输入不一致,请重新输入
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Form.Row>
                    <Button onClick={this.regist } disabled={!this.state.datas_valid} className="mt-3" variant="primary" type="button" block> 注册 </Button>
                </Form>

            </Modal.Body>
        </Modal>
    }

}

