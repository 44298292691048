import React from 'react';
import {
    Table,

} from 'react-bootstrap';

export default function tableRender(columns, datas, opts = {}) {
    return <Table
        className="info-table"
        striped={opts.striped || false}
        bordered={opts.bordered === undefined ? true : opts.bordered}
        hover
        size="sm"
        style={{ width: '100%', tableLayout: 'fixed' }}
    >
        {columns && columns.map(item => {
            return <col style={{ width: item.width || 10 }}></col>;
        })}
        <thead>
            <tr className="text-center">
                {columns && columns.map(item => {
                    return <th>{item.title}</th>;
                })}
            </tr>
        </thead>
        <tbody>
            {
                datas && datas.length ? datas.map((item, index) => {
                    return <tr>
                        {
                            columns && columns.map(col => {
                                return <td className={col.align && 'text-' + col.align}>{col.render ? col.render(item[col.field], item, index) : item[col.field]}</td>;
                            })
                        }
                    </tr>;
                })
                    :
                    !opts.empty ? null
                        : <tr>
                            <td colSpan={columns.length}>
                                {opts.empty}
                            </td>
                        </tr>

            }
        </tbody>
    </Table>
}