
import moment from 'moment';

/**
 * 此刻年月日
 */
export const date = () => {
    return moment(Date.now()).format('YYYY-MM-DD');
}

/**
 * 此刻 年月日 时分
 */
export const now = () => {
    return moment(Date.now()).format('YYYY-MM-DD HH:mm');
}

/**
 * 今天凌晨零时 年月日 时分
 */
export const time0 = () => {
    return moment(Date.now()).format('YYYY-MM-DD')+' 00:00';
}

/**
 * 今天23:59 年月日 时分
 */
export const time2 = () => {
    return moment(Date.now()).format('YYYY-MM-DD')+' 23:59';
}

/**
 * 格式化为 YYYY-MM-DD
 * @param {*} date 
 */
export const format = (date) => {
    return moment(date).format('YYYY-MM-DD');
}

/**
 * 格式化为 YYYY-MM-DD HH:mm
 * @param {} date 
 */
export const format1 = (date) => {
    return moment(date).format('YYYY-MM-DD HH:mm');
}

