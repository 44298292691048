import React, { Component } from 'react';
import {
    Button, ButtonGroup, Nav, ToggleButton, Navbar, NavDropdown, Form, FormControl, Carousel,
    Container,
    Card,
    Row,
    Col,
    ListGroup,
} from 'react-bootstrap';


import NavLink from '../../component/NavLink';

import { get, post, bind, off } from '../../utils/common';

import src_bar_friends from '../../images/bar_friends.jpg';


var categorys = [
    {
        value: '全国',
        color: ''
    },
    {
        value: '华东',
        color: '1'
    },
    {
        value: '华北',
        color: '2'
    },
    {
        value: '华南',
        color: '3'
    },
    {
        value: '华中',
        color: '4'
    },
    {
        value: '西南',
        color: '5'
    },
    {
        value: '东北',
        color: '6'
    },
    {
        value: '西北',
        color: '7'
    }
];

var color_map = {};
categorys.forEach( item => {
    color_map[item.value] = item.color;
});
// console.log(color_map)

var datas = []

class View extends Component {

    constructor(props) {
        super(props);
        this.state = {
            index: this.index = 0,
            items: datas || [],
            sticky: false
        }
    }


    componentDidMount() {
        if (!datas.length) {
            post('infoCompany/infoCompanyList', {}, res => {
                datas = res && res.data || [];
                // datas.sort((a, b) => a.companyName.localeCompare(b.companyName));
                this.init();
            })
        } else {
            this.init();
        }
        bind(window, 'scroll', this.onScroll);
        this.onScroll();
    }

    componentWillUnmount() {
        off(window, 'scroll', this.onScroll);
    }

    active = (index) => {
        this.setState({
            index: this.index = index
        });
        this.init();
    }

    init() {
        if (this.index === 0) {
            this.setState({
                items: datas
            })
        } else {
            var cate = categorys[this.index];
            this.setState({
                items: datas.filter(item => item.cityArea === cate.value)
            })
        }
    }


    refMenuWrap = (ref) => {
        this.menuwrap = ref;
    }

    onScroll = () => {
        if (this.menuwrap) {
            this.setState({
                sticky: document.documentElement.scrollTop > this.menuwrap.offsetTop - 43
            });
        }
    }

    render() {

        return (
            <>
                <Container className="p-0" fluid style={{ backgroundImage: `url("${src_bar_friends}")`, height: 300, color: 'white', textShadow: '0 0 0 #ff88ff' }}>
                    <div className="d-flex align-items-center flex-column justify-content-center" style={{ backgroundColor: 'rgba(0,0,0,.3)', width: '100%', height: '100%', paddingTop: 60 }}>
                        <h1 className="mb-4 font-weight-bold">
                            服务资源
                        </h1>
                        <h4 className="mt-3 mb-3">
                            唐古信息携全国煎药中心、智慧中药房和共享中药房，赋能中医服务
                        </h4>

                    </div>
                </Container>
                <div ref={this.refMenuWrap} className="bg-white pt-3 mb-3" style={{height: '4rem'}}>
                    <div className={"bg-white pt-3 pb-2 border-bottom " + (this.state.sticky ? ' sticky-menu' : '')} style={{top: 59, borderBottom: '1px solid #fbfbfb'}}>
                        <Container className="my-0 p-0 " >
                            <ListGroup horizontal={true} className="px-3" size="sm">
                                {categorys.map((item, index) => {
                                    return <ListGroup.Item variant={'light'} className={"mx-0 px-3 py-0 my-1" + (this.state.index === index ? ' text-warning' : '')} style={{ border: 0, cursor:'pointer', borderLeft: index === 0 ? '0' : '2px solid #bbb', color: '#666', fontWeight: index === 0 ? 800 : 400, lineHeight: 1, fontSize: '1.0rem' }} onClick={() => this.active(index)} >{item.value}</ListGroup.Item>
                                })}
                            </ListGroup>
                        </Container>
                    </div>
                </div>
                <Container className="my-3 d-flex flex-row" style={{
                    flexWrap: 'wrap'
                }} >
                    {
                        !this.state.items.length ?
                            <h4 className="my-5 text-center text-secondary" style={{ width: '100%' }}>期待您的加入！</h4>
                            :
                            this.state.items.map(item => {
                                return <div style={{ width: '33.333%' }}>
                                    <div className="m-1 p-5 border rounded bg-white shadow-sm position-relative overflow-hidden">
                                        <h4 className="mb-3 font-weight-bold " title={item.companyName} style={{ color: '#444', height: '3.6rem' }} >{item.companyName}</h4>
                                        <p className="m-0">所在地区：{item.province} {item.city}</p>
                                        <div className={"slash-label slash-label-color-"+color_map[item.cityArea]} >
                                            {item.cityArea}
                                        </div>
                                    </div>
                                </div>
                            })
                    }
                </Container>
            </>
        );
    }
}

export default View;
