import React, { Component } from 'react';
import {
    Button, ButtonGroup, Nav, ToggleButton, Navbar, NavDropdown, Form, FormControl, Carousel,
    Container,
    Card,
    Row,
    Col,
    Table,
    Tabs, Tab,

} from 'react-bootstrap';

import {
    HashRouter as Router,
    Switch,
    Route,
    useRouteMatch
} from "react-router-dom";

import NavLink from '../../component/NavLink';

import { get } from '../../utils/common';

import src_postman from '../../images/postman.png';

import src_bar_api from '../../images/bar_api.jpg';

import tableRender from '../../component/tableRender';


var menus;

const columns_urls = [{
    title: '环境',
    field: 'name'
}, {
    title: '请求地址',
    width: 30,
    field: 'url'
}];

const columns_commonparams = [{
    title: '参数',
    field: 'source'
}, {
    title: '类型',
    width: 6,
    align: 'center',
    field: 'fieldType'
}, {
    title: '是否必填',
    width: 6,
    align: 'center',
    field: 'required'
}, {
    title: '最大长度',
    width: 6,
    align: 'center',
    field: 'length'
}, {
    title: '描述',
    width: 20,
    field: 'description'
}, {
    title: '示例值',
    field: 'example'
}]


const columns_response = [{
    title: '参数',
    field: 'source'
}, {
    title: '类型',
    width: 6,
    align: 'center',
    field: 'fieldType'
}, {
    title: '最大长度',
    width: 6,
    align: 'center',
    field: 'length'
}, {
    title: '描述',
    width: 20,
    field: 'description'
}, {
    title: '示例值',
    field: 'example'
}]

const columns_codes = [{
    title: '代码',
    align: 'center',
    field: 'itemKey'
}, {
    title: '含义',
    width: 20,
    field: 'itemValue'
}];

const url_api_code = '/api/code';
const url_api_download = '/api/download';

var codes_datas;

class View extends Component {

    constructor(props) {
        super(props);
        this.state = {
            menus: menus || [],
            detail: {},
            info: {}
        }
    }

    componentDidMount() {
        this.initdatas(this.props);
    }

    componentDidUpdate(prevProps) {
        if (this.props.route.url !== prevProps.route.url) {
            this.initdatas(this.props);
        }
    }

    initdatas(props) {
        if (!menus) {
            get('showapi/listApiName', (json) => {
                console.log('json', json);
                if (json && json.data) {
                    menus = json.data || [];
                }
                (menus || []).forEach(item => {
                    item.common = item.commonParam ? JSON.parse(item.commonParam) : {}
                    item.help = item.helpUrl ? JSON.parse(item.helpUrl) : [];
                    item.example = item.requestResponseExample ? JSON.parse(item.requestResponseExample) : {}
                });
                this._initdatas(props);
            });
        } else {
            this._initdatas(props);
        }
        if (this.props.route.url === url_api_code) {
            if (!codes_datas) {
                get('distionaryItem/distionaryList', (json) => {
                    if (json && json.data) {
                        codes_datas = json.data || [];
                    }
                    this.setState({});
                });
            }
        }
    }

    _initdatas(props) {
        var state = {
            menus
        };
        var id = Number(props.route.params.id);
        state.detail = (menus || [])[0] || {};
        if (id) {
            menus.forEach(item => {
                if (item.id === id) {
                    state.detail = item;
                }
            })
        }
        if (props.route.url !== url_api_code && props.route.url !== url_api_download && (!id || (menus.length && state.detail.id != id))) {
            location.hash = '#/api/' + menus[0].id;
            return;
        }
        this.setState(state);
        this.loadDetail(id);

    }

    loadDetail(id) {
        if (id) {
            get('showapi/getApiDetail/' + id, (json) => {
                if (this.state.detail.id === id) {
                    this.setState({
                        info: json || {}
                    })
                }
            });
        }

    }

    renderDetail() {
        var detail = this.state.detail;
        var info = this.state.info || {};
        console.log(detail);
        console.log(info);
        return <div>
            <h3>{detail.name}</h3>
            <p>
                {detail.description}
            </p>
            <h3>公共参数</h3>
            <h5>请求地址</h5>
            {tableRender(columns_urls, detail.common && detail.common.urls)}
            <h5>Header 请求头参数</h5>
            {tableRender(columns_commonparams, detail.common && detail.common.params)}

            <h3>请求参数</h3>
            {tableRender(columns_commonparams, info.requestList, { empty: <div className="text-center" style={{ color: '#bbb', padding: 10 }}>暂无数据</div> })}

            <h3>响应参数</h3>
            {tableRender(columns_response, info.responseList, { empty: <div className="text-center" style={{ color: '#bbb', padding: 10 }}>暂无数据</div> })}

            <h3>请求示例</h3>
            <Tabs defaultActiveKey="json"  activeKey={ detail.requestXml ? undefined : 'json'}>
                <Tab eventKey="json" title="Json">
                    <code className="code pre-scrollable p-3 border border-top-0">{detail.example && detail.example.request && JSON.stringify(detail.example.request, null, 4)}</code>
                </Tab>
                {
                    !detail.requestXml ? null : 
                    <Tab eventKey="xml" title="Xml">
                        <code className="code pre-scrollable p-3 border border-top-0">{detail.requestXml}</code>
                    </Tab>
                }
            </Tabs>

            <h3>响应示例</h3>
            <Tabs defaultActiveKey="json" activeKey={ detail.responseXml ? undefined : 'json'} >
                <Tab eventKey="json" title="Json" >
                    <code className="code pre-scrollable p-3 border border-top-0">{detail.example && detail.example.response && JSON.stringify(detail.example.response, null, 4)}</code>
                </Tab>
                {
                    !detail.responseXml ? null :
                    <Tab eventKey="xml" title="Xml">
                        <code className="code pre-scrollable p-3 border border-top-0">{detail.responseXml}</code>
                    </Tab>
                }
            </Tabs>

            {
                detail.help && detail.help.map(item => {
                    return <div>
                        <h3 className="">{item.name}</h3>
                        <p>{item.description}</p>
                        {
                            item.urls && item.urls.map(url => {
                                return <div className="my-3 border">
                                    <a href={url} target="_download">
                                        <img src={url} style={{ maxWidth: '100%' }}></img>
                                    </a>
                                </div>
                            })
                        }
                    </div>
                })
            }

        </div>
    }

    '/api/download' = () => {

        return <div >
            <h3 >下载测试工具</h3>
            <ul className="pl-4" style={{ listStyle: 'decimal' }}>
                <li >
                    <p>
                        测试工具下载链接：<a href="https://www.postman.com/downloads/" target="_blank"> 下载postman </a>
                    </p>
                </li>
                <li >
                    <p>
                        进入下载界面，点击界面“Download”进行下载
                    </p>
                    <p>
                        <img src={src_postman}></img>
                    </p>
                </li>
                <li >
                    <p>
                        下载安装完成，进行自助测试。
                    </p>
                </li>
            </ul>
        </div>

    }

    [url_api_code] = () => {
        return <div>
            {codes_datas && codes_datas.map(row => {
                return <div>
                    <h3>{row.name}</h3>
                    {tableRender(columns_codes, row.distionaryItem, { empty: <div className="text-center" style={{ color: '#bbb', padding: 10 }}>暂无数据</div> })}
                </div>
            })}
        </div>
    }

    render() {

        // console.log('codes_datas', codes_datas);

        return (
            <>
                <Container className="p-0 d-flex align-items-center flex-column justify-content-center" fluid style={{ backgroundImage: `url("${src_bar_api}")`, height: 300, color: 'rgba(255,255,255,1)', textShadow: '0 0 10px blue' }}>
                    <h1 className="mb-4 font-weight-bold" style={{ paddingTop: 60 }}>
                        API文档
                    </h1>
                    <h4 className="mt-3 mb-3">
                        无论您是需求方还是供应方，均可基于开放平台的接口快速接入应用
                    </h4>
                </Container>
                <Container className="border bg-white my-3" style={{ minHeight: '70%' }} >
                    <Row>
                        <Col xs={2} >
                            <Navbar sticky='top' className="p-0" style={{ top: 59 }}>
                                <Nav className="flex-column tree-menu py-3">
                                    {(this.state.menus || []).map(item => {
                                        return <NavLink href={`#/api/${item.id}`} key={item.url}>{item.name}</NavLink>;
                                    })}
                                    <NavLink href={`#/api/download`} className="border-top"><span className="iconfont icon-Group"></span><span style={{ marginLeft: '1em' }}>测试工具</span></NavLink>
                                    <NavLink href={`#${url_api_code}`} ><span className="iconfont icon-zidian"></span><span style={{ marginLeft: '1em' }}>字典查询</span></NavLink>
                                </Nav>
                            </Navbar>
                        </Col>
                        <Col className="border-left">
                            <div className="doc px-3 pb-3 pt-0" >
                                {
                                    this[this.props.route.url] ? this[this.props.route.url]() : this.renderDetail()
                                }
                            </div>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }
}

export default function (props) {
    var route = useRouteMatch();
    return <View {... { ...props, route }}></View>
};
