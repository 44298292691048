import React, { Component } from 'react';
import 'bootstrap/scss/bootstrap.scss';
import './css/iconfont/iconfont.css';
import './index.css';
import './App.css';
import {
  Button, ButtonGroup, Nav, ToggleButton, Navbar, NavDropdown, Form, FormControl, Carousel,
  Container,
  Card,
  Row,
  Col,
  Dropdown,

} from 'react-bootstrap';
import {
  HashRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import NavLink from './component/NavLink';
import Notification from './component/Notification';

import HomeView from './views/home/HomeView';
import CatalogView from './views/catalog/CatalogView';
import ApiView from './views/api/ApiView';

import LoginDialog from './views/user/LoginDialog';
import RegistDialog from './views/user/RegistDialog';
import FriendsView from './views/friends/FriendsView';
import MyView from './views/my/MyView';

// D:\tg\work\nw\tangu-open-ui\src\views\friends\FriendsView.js

import logo from './images/logo.png';

import { initLogindata, bind, off } from './utils/common';
import { build, put, init } from './utils/mixstate';

init('logindata', {});

var mixer = build(['logindata']);

var bg_dark = 'rgba(52, 58, 64, .95)', bg_opacity = 'rgba(52, 58, 64, .5)';

var notificationInstance;

const cacheNotification = (el) => {
  notificationInstance = el;
}

export const toast = (cfg) => {
  notificationInstance && notificationInstance.show(cfg);
}

window.toast = toast;

export default class App extends Component {

  constructor(props) {

    super(props);
    this.state = {
      show: false,
      headerbg: bg_opacity
    };
    mixer.mix(this);

  }

  componentDidMount() {
    bind(window, 'scroll', this.onScroll);
    this.onScroll();
  }

  componentWillUnmount() {
    off(window, 'scroll', this.onScroll);
  }

  login = () => {
    put('login_time_login', Date.now())
  }

  regist = () => {
    put('login_time_regist', Date.now())
  }

  quit = () => {
    initLogindata()
  }

  refHeader = (ref) => {
    this.header = ref;
  }

  onScroll = () => {
    // console.log(' scroll ');
    if (this.header) {
      var next = this.header.nextElementSibling;
      if (next) {
        var headerbg = document.documentElement.scrollTop > next.getBoundingClientRect().height ? bg_dark : bg_opacity;
        this.setState({
          headerbg
        });
      }
    }
  }

  userInfoDropdownMouseEnter = () => {
    this.setState({
      showUserDropdown: true
    })
  }

  userInfoDropdownMouseLeave = () => {
    this.setState({
      showUserDropdown: false
    })
  }

  render() {

    return <>
      <Router>
        <div className="app" style={{ minHeight: '85%' }}>
          <div ref={this.refHeader} className="bg-transition" style={{ backgroundColor: this.state.headerbg, position: 'fixed', width: '100%', zIndex: 1000 }}  >
            <Container className="px-0 shadow-sm" >
              <Navbar variant="dark" >
                <Navbar.Brand className="mr-5" href="#/" >
                  <img alt='' src={logo} style={{ height: '1.5em', marginRight: '.5em' }}></img>
                  唐古远志中药处方流转平台 {this.state.count}
                </Navbar.Brand>
                {/* <Navbar.Toggle aria-controls="basic-navbar-nav" /> */}
                <Navbar.Collapse id="basic-navbar-nav" >
                  <Nav className="mr-auto" defaultActiveKey={location.hash || "#/"}>
                    <NavLink exact href="#/" >主页</NavLink>
                    <NavLink href="#/catalog/">文档中心</NavLink>
                    <NavLink href="#/api/">API</NavLink>
                    <NavLink href="#/friends">服务资源</NavLink>
                    {/* <NavLink href="#link">数据中心</NavLink> friends
                      <NavLink href="#link">帮助中心</NavLink> */}
                  </Nav>
                  <div className="text-white d-flex flex-row align-items-center" style={{ textAlign: 'center' }}>
                    {
                      !this.state.logindata.username ?
                        <>
                          <span className="iconfont icon-yonghu circle " style={{ marginRight: '.5em', color: '#aaa', borderColor: '#aaa' }}></span>
                          <Button size="sm" className="bg-transparent border-0" onClick={this.login} >登录</Button>
                      /
                      <Button size="sm" className="bg-transparent border-0" onClick={this.regist} >注册</Button>
                        </>
                        :
                        <Dropdown onMouseEnter={this.userInfoDropdownMouseEnter} show={this.state.showUserDropdown} onMouseLeave={this.userInfoDropdownMouseLeave} >
                          <Dropdown.Toggle as="div" variant="success" id="dropdown-basic">
                            <span className="iconfont icon-yonghu circle " style={{ marginRight: '.5em' }}></span>
                            {this.state.logindata.username}
                          </Dropdown.Toggle>
                          <Dropdown.Menu style={{ marginTop: 0, paddingTop: 8, backgroundColor: 'transparent', border: 0 }}>
                            <div style={{ backgroundColor: 'white', borderRadius: 5, padding: '10px 0', border: '1px solid rgba(0, 0, 0, 0.15)' }}>
                              <div onClick={this.userInfoDropdownMouseLeave}>
                                <Dropdown.Item href="#/my/">我的信息</Dropdown.Item>
                                <Dropdown.Item href="#/my/apps">我的应用</Dropdown.Item>
                                <Dropdown.Item href="#/my/dock">机构列表</Dropdown.Item>
                                <Dropdown.Item onClick={this.quit} >退出</Dropdown.Item>
                              </div>
                            </div>
                          </Dropdown.Menu>
                        </Dropdown>
                    }
                  </div>
                </Navbar.Collapse>
              </Navbar>
            </Container>
          </div>
          <Switch>
            <Route exact path="/">
              <HomeView />
            </Route>
            <Route path="/catalog">
              <CatalogView />
            </Route>
            <Route path="/api" exact component={ApiView} />
            <Route path="/api/:id" component={ApiView} />
            <Route path="/friends" component={FriendsView} />
            <Route path="/my" component={MyView} />
          </Switch>
        </div>
        <footer className="text-center" style={{ margin: '4rem 0 6rem 0' }}>
          <a href="http://www.tangutek.com">
            ©杭州唐古信息科技有限公司 2009-2020 All Rights Reserved
            </a>
          |
            <a href="http://www.beian.miit.gov.cn/">
            浙ICP备09073022号-4
            </a>
        </footer>
        <LoginDialog />
        <RegistDialog />
      </Router>
      <Notification ref={cacheNotification} />
    </>;
  }

}


