import React, { Component } from 'react';
import {
    Button, ButtonGroup, Nav, ToggleButton, Navbar, NavDropdown, Form, FormControl, Carousel,
    Container,
    Card,
    Row,
    Col,
    Modal,
    InputGroup,

} from 'react-bootstrap';

import { post, initLogindata, get } from '../../../utils/common';
import { put, build } from '../../../utils/mixstate';
import { toast } from '../../../App';
import FormItem from '../../../component/FormItem';
import types from '../../../datas/userTypes';

var mixer = build(['time_apply_app']);

export default class View extends Component {

    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            validated: false,
            datas: {},
            applications: []
        };
        mixer.mix(this);
    }

    componentDidMount(){
        
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.time_apply_app !== prevState.time_apply_app && this.state.time_apply_app) {
            this.setState({
                visible: true,
                validated: false,
                datas: {}
            });
        }
    }

    onHide = () => {
        this.setState({
            visible: false
        });
    }

    save = () => {

        post('usersApplication/registerApplyHospital', this.state.datas, json => {
            if (json.errorMsg) {
                toast({
                    title: '错误',
                    message: json.errorMsg
                })
                return;
            } else if (json.feedbackMsg) {
                toast({
                    type: 'info',
                    message: json.feedbackMsg
                });
            }
            this.onHide();
            this.props.onApplyed && this.props.onApplyed();
            
        }, err => {
            toast({
                title: '错误',
                message: err
            });
        });

    } 

    render() {

        return <Modal show={this.state.visible} onHide={this.onHide} centered backdrop="static" >
            <span className="dialog-close-button iconfont icon-guanbi" onClick={this.onHide} ></span>
            <Modal.Body className="mx-5 my-3">
                <div className="mb-3">
                    <h4 className="text-center mb-5 font-weight-bold">
                        <span className="border-primary text-bold px-3 pb-2" style={{ borderBottom: '4px solid blue' }}>机构申请</span>
                    </h4>
                </div>
                <Form ref={this.refForm} noValidate validated={false} >
                    <Form.Row>
                        <Form.Label column className="text-right">
                            <span className="red">*</span>
                            医疗机构名称:
                        </Form.Label>
                        <Form.Group as={Col} md="7">
                          <FormItem
                              statename="datas"
                              context={this} type="text"
                              required minLength="2"
                              name="name"
                          />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Label column className="text-right">
                            <span className="red">*</span>
                            申请用途:
                        </Form.Label>
                        <Form.Group as={Col} md="7">
                            <FormItem
                                statename="datas"
                                context={this} type="text"
                                required minLength="2"
                                name="description"
                            />
                        </Form.Group>
                    </Form.Row>
                    <div style={{textAlign: 'center'}}>
                        <Button onClick={this.save} disabled={!this.state.datas_valid} className="mt-3" variant="primary" type="button" style={{width: '50%'}} > 确定 </Button>
                    </div>
                </Form>

            </Modal.Body>
        </Modal>
    }

}

