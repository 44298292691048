import React from 'react';
import {
    Nav
} from 'react-bootstrap';
import {
  HashRouter as Router,
  Switch,
  Route,
  useRouteMatch
} from "react-router-dom";

function Link(props){
    useRouteMatch();
    return <Nav.Link {...props } active={ props.exact ?  location.hash === props.href : location.hash.indexOf(props.href) === 0 } >{props.children}</Nav.Link>;
}

function scrollTop(){
    document.body.parentElement.scrollTop = 0;
}

export default function NavLink(props){
    if (props.active !== undefined){
        return <Nav.Link {...props} onClick={ scrollTop } >{props.children}</Nav.Link>;
    } else {
        return <Link {...props} onClick={ scrollTop } >{props.children}</Link>;
    }
}
